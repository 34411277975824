import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
// components
import Layout from "../../components/layout"
import TitleLeft from "../../components/molecules/title_left"
import Posts from "../../components/molecules/posts"
import SEO from "../../components/seo"

const StyledNews = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
  padding: 15px 0;
`

const Wrapper = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  grid-column-end: -2;
  grid-column-start: 2;
  min-height: calc(100vh - 169px);
  overflow: hidden;
  padding: 25px;

  @media (max-width: 767px) {
    min-height: calc(100vh - 137px);
  }
`

const News = ({
  data,
  location
}) => {
  const posts = data.allMicrocmsNews.edges.map(({ node }) => node)
  return (
    <Layout location={location}>
      <StyledNews>
        <Wrapper>
          <TitleLeft
            level={1}
            fontSize="20px"
            heading="お知らせ"
          />
          <Posts
            posts={posts}
            slug="news"
          />
        </Wrapper>
      </StyledNews>
    </Layout>
  )
}

export default News

export const query = graphql`
  query {
    allMicrocmsNews(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id: newsId
          title
          date
          formattedDate: date(formatString: "YYYY年MM月DD日")
        }
      }
    }
  }
`

export const Head = ({ location }) => (
  <SEO
    title="お知らせ | ブログ"
    description="株式会社マルトモのブログページです。"
    pathname={location.pathname}
  />
)
